import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import { Button, Chip } from "@legion-ui/core";
import Lottie from "react-lottie-player";
import { IconArrowRight } from "@tabler/icons-react";
import lottieJson from "./Landingnew2.json";
import lottieJsonTwo from "./lottie_json_two.json";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div {...{
      "id": "who-you-are",
      "style": {
        "marginBottom": "48px"
      }
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "marginBottom": "24px"
        }
      }}>{`
    `}<h2 parentName="div">{`Let us know, who you are`}</h2>{`
    `}<p parentName="div" {...{
          "className": "text-tertiary-60",
          "style": {
            "fontSize": "1rem"
          }
        }}>{`
      By clicking one of these cards below, we’ll recognize who you are
    `}</p>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "grid sm-grid-cols-1 grid-cols-2 sm-gap-y-6 gap-y-6 gap-x-6"
      }}>{`
    `}<div parentName="div" {...{
          "className": "bg-tertiary-100 p-8 rounded-2xl border border-tertiary-20"
        }}>{`
      `}<div parentName="div" {...{
            "className": "role-card"
          }}>{`
        `}<div parentName="div" {...{
              "className": "flex justify-center items-center bg-warning-500 w-14-125 h-14-125 rounded-lg"
            }}>{`
          `}<object parentName="div" {...{
                "type": "image/svg+xml",
                "data": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/home/cube.svg",
                "aria-label": "cube icon"
              }}>{`
            Cube Icon
          `}</object>{`
        `}</div>{`
        `}<h2 parentName="div" {...{
              "className": "text-xl font-bold text-secondary-70 role-title"
            }}>{`Designer`}</h2>{`
      `}</div>{`
      `}<div parentName="div" {...{
            "className": "role-description"
          }}>{`
        `}<p parentName="div" {...{
              "className": "text-tertiary-60",
              "style": {
                "minHeight": "64px"
              }
            }}>{`
          Let’s find out everything you before start designing with
        `}</p>{`
      `}</div>{`
      `}<a parentName="div" {...{
            "className": "Anchor",
            "href": "/design/getting-started/for-telkom-employee"
          }}>{`
        `}<div parentName="a" {...{
              "style": {
                "display": "flex",
                "alignItems": "center",
                "fontWeight": "700"
              },
              "className": "role-anchor"
            }}>{`
          Yes, I am a Designer `}<iconarrowright parentName="div" {...{
                "size": "{20}",
                "style": {
                  "marginLeft": "10px"
                }
              }}>{`
        `}</iconarrowright></div>{`
      `}</a>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "className": "bg-tertiary-100 p-8 rounded-2xl border border-tertiary-20"
        }}>{`
      `}<div parentName="div" {...{
            "className": "role-card"
          }}>{`
        `}<div parentName="div" {...{
              "className": "flex justify-center items-center bg-danger-500 w-14-125 h-14-125 rounded-lg"
            }}>{`
          `}<object parentName="div" {...{
                "type": "image/svg+xml",
                "data": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/home/dashboard.svg",
                "aria-label": "dashboard icon"
              }}>{`
            Dashboard Icon
          `}</object>{`
        `}</div>{`
        `}<h2 parentName="div" {...{
              "className": "text-xl font-bold text-secondary-70 role-title"
            }}>{`Developer`}</h2>{`
      `}</div>{`
      `}<div parentName="div" {...{
            "className": "role-description"
          }}>{`
        `}<p parentName="div" {...{
              "className": "text-tertiary-60",
              "style": {
                "minHeight": "64px"
              }
            }}>{`
          Find out how to setup, configure, and use our library or code assets
        `}</p>{`
      `}</div>{`
      `}<a parentName="div" {...{
            "className": "Anchor",
            "href": "/development/installation/website"
          }}>{`
        `}<div parentName="a" {...{
              "style": {
                "display": "flex",
                "alignItems": "center",
                "fontWeight": "700"
              },
              "className": "role-anchor"
            }}>{`
          Yes, I am a Developer `}<iconarrowright parentName="div" {...{
                "size": "{20}",
                "style": {
                  "marginLeft": "10px"
                }
              }}>{`
        `}</iconarrowright></div>{`
      `}</a>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "className": "bg-tertiary-100 p-8 rounded-2xl border border-tertiary-20"
        }}>{`
      `}<div parentName="div" {...{
            "className": "role-card"
          }}>{`
        `}<div parentName="div" {...{
              "className": "flex justify-center items-center bg-success-500 w-14-125 h-14-125 rounded-lg"
            }}>{`
          `}<object parentName="div" {...{
                "type": "image/svg+xml",
                "data": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/home/leaf.svg",
                "aria-label": "leaf icon"
              }}>{`
            Leaf Icon
          `}</object>{`
        `}</div>{`
        `}<h2 parentName="div" {...{
              "className": "text-xl font-bold text-secondary-70 role-title"
            }}>{`Writer`}</h2>{`
      `}</div>{`
      `}<div parentName="div" {...{
            "className": "role-description"
          }}>{`
        `}<p parentName="div" {...{
              "className": "text-tertiary-60",
              "style": {
                "minHeight": "64px"
              }
            }}>{`
          It will guide you to know how choose the copywriting
        `}</p>{`
      `}</div>{`
      `}<a parentName="div" {...{
            "className": "Anchor",
            "href": "/content/writing-guideline"
          }}>{`
        `}<div parentName="a" {...{
              "style": {
                "display": "flex",
                "alignItems": "center",
                "fontWeight": "700"
              },
              "className": "role-anchor"
            }}>{`
          Yes, I am a Writer `}<iconarrowright parentName="div" {...{
                "size": "{20}",
                "style": {
                  "marginLeft": "10px"
                }
              }}>{`
        `}</iconarrowright></div>{`
      `}</a>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "className": "bg-tertiary-100 p-8 rounded-2xl border border-tertiary-20"
        }}>{`
      `}<div parentName="div" {...{
            "style": {
              "display": "flex",
              "justifyContent": "space-between",
              "alignItems": "center",
              "marginBottom": "12px"
            }
          }}>{`
        `}<div parentName="div" {...{
              "className": "role-card",
              "style": {
                "marginBottom": "0"
              }
            }}>{`
          `}<div parentName="div" {...{
                "className": "flex justify-center items-center bg-secondary-500 w-14-125 h-14-125 rounded-lg"
              }}>{`
            `}<img parentName="div" {...{
                  "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/home/illustrator-icon.svg",
                  "style": {
                    "width": "100%",
                    "height": "100%"
                  }
                }}></img>{`
          `}</div>{`
          `}<h2 parentName="div" {...{
                "className": "text-xl font-bold text-secondary-70 role-title"
              }}>{`Illustrator`}</h2>{`
        `}</div>{`
        `}<span parentName="div" {...{
              "className": "legion-chip legion-chip--solid-success legion-chip--sm css-16mkni6"
            }}>{`
          `}<span parentName="span" {...{
                "className": "legion-chip__text"
              }}>{`New`}</span>{`
        `}</span>{`
      `}</div>{`
      `}<div parentName="div" {...{
            "className": "role-description"
          }}>{`
        `}<p parentName="div" {...{
              "className": "text-tertiary-60",
              "style": {
                "minHeight": "64px"
              }
            }}>{`
          Unleash creativity, refine storytelling, precision in action.
        `}</p>{`
      `}</div>{`
      `}<a parentName="div" {...{
            "className": "Anchor",
            "href": "/illustrations/overview"
          }}>{`
        `}<div parentName="a" {...{
              "style": {
                "display": "flex",
                "alignItems": "center",
                "fontWeight": "700"
              },
              "className": "role-anchor"
            }}>{`
          Yes, I am an Illustrator `}<iconarrowright parentName="div" {...{
                "size": "{20}",
                "style": {
                  "marginLeft": "10px"
                }
              }}>{`
        `}</iconarrowright></div>{`
      `}</a>{`
    `}</div>{`
  `}</div>
    </div>
    <div id="before-designing" style={{
      "marginBottom": "48px"
    }}>
  <div style={{
        "marginBottom": "24px"
      }}>
    <h2>Find out your brand &amp; assets</h2>
    <p className="text-tertiary-60" style={{
          "fontSize": "1rem"
        }}>
      Take a look first to components we have and pattern we used
    </p>
  </div>
  <div className="grid sm-grid-cols-1 grid-cols-3 sm-gap-y-6 gap-x-6">
    <div className="bg-tertiary-100 p-6 rounded-2xl border border-tertiary-20">
      <h2 className="text-xl font-bold text-secondary-70 mt-0">Foundations</h2>
      <p className="text-tertiary-60 mt-4 mb-4" style={{
            "minHeight": "96px"
          }}>
        Fundamental brand property used in styling components or crafting screen
      </p>
      <a className="Anchor" href="/foundation/overview">
        <div style={{
              "display": "flex",
              "alignItems": "center"
            }} className="role-anchor">
          See all Foundations <IconArrowRight size={20} style={{
                "marginLeft": "10px"
              }} mdxType="IconArrowRight" />
        </div>
      </a>
    </div>
    <div className="bg-tertiary-100 p-6 rounded-2xl border border-tertiary-20">
      <h2 className="text-xl font-bold text-secondary-70 mt-0">Components</h2>
      <p className="text-tertiary-60 mt-4 mb-4" style={{
            "minHeight": "96px"
          }}>
        Our core set of components are accessible and themeable accross products
      </p>
      <a className="Anchor" href="/components/overview">
        <div style={{
              "display": "flex",
              "alignItems": "center"
            }} className="role-anchor">
          See all components <IconArrowRight size={20} style={{
                "marginLeft": "10px"
              }} mdxType="IconArrowRight" />
        </div>
      </a>
    </div>
    <div className="bg-tertiary-100 p-6 rounded-2xl border border-tertiary-20">
      <h2 className="text-xl font-bold text-secondary-70 mt-0">Patterns</h2>
      <p className="text-tertiary-60 mt-4 mb-4" style={{
            "minHeight": "96px"
          }}>
        Pattterns Library is collections of typical design problems, recommendation and solution
      </p>
      <a className="Anchor" href="/pattern/overview">
        <div style={{
              "display": "flex",
              "alignItems": "center"
            }} className="role-anchor">
          See all patterns <IconArrowRight size={20} style={{
                "marginLeft": "10px"
              }} mdxType="IconArrowRight" />
        </div>
      </a>
    </div>
  </div>
    </div>
    <div className="row1" style={{
      "backgroundColor": "#EEF0F7",
      "borderRadius": "12px",
      "overflow": "hidden",
      "marginBottom": "48px"
    }}>
  <div className="double-column1 homelottie">
    <h6 style={{
          "width": "100%",
          "marginBottom": "8px",
          "marginTop": "0",
          "fontSize": "20px",
          "fontWeight": "700"
        }}>
      Support +10 digital products brands
    </h6>
    <p className="text-tertiary-60" style={{
          "marginBottom": "1rem"
        }}>
      Let’s make it better with legion
    </p>
    <a className="Anchor" href="/about-legion" style={{
          "width": "fit-content !important"
        }}>
      See more details
    </a>
  </div>
  <div className="column1" style={{
        "flex": "1.5"
      }}>
    <Lottie className="lottiesvg" animationData={lottieJson} rendererSettings={{
          preserveAspectRatio: "xMidYMid slice"
        }} loop play mdxType="Lottie" />
  </div>
    </div>
    <div {...{
      "className": "row2",
      "style": {
        "backgroundColor": "#EEF0F7",
        "borderRadius": "12px",
        "overflow": "hidden"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1",
        "style": {
          "flex": "1.5"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/home/home-integrated-design.svg",
          "alt": "10 plus products with legion",
          "style": {
            "paddingLeft": "1rem",
            "height": "100%",
            "objectFit": "cover",
            "objectPosition": "left center"
          }
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1 homelottie"
      }}>{`
    `}<h6 parentName="div" {...{
          "style": {
            "width": "100%",
            "marginBottom": "8px",
            "marginTop": "0",
            "fontSize": "20px",
            "fontWeight": "700"
          }
        }}>{`
      Integrated design and code `}<br parentName="h6"></br>{` asset support
    `}</h6>{`
    `}<p parentName="div" {...{
          "className": "text-tertiary-60",
          "style": {
            "marginBottom": "1rem"
          }
        }}>{`
      Design token implementation for consistency in multiple brand and platform
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      